import React, {Component} from 'react';
import Metamask from '../assets/img/GetMetamask.png';

class GetMetamask extends Component {
    render() {
        return (
            <div style={{height: "60rem"}} align="center">
                <img style={{marginTop: "20rem", width:"50%"}} src={Metamask} alt={'metamask'}/><br/>
                <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn">
                For Chrome
                </a><br/>
                <a href="https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/">
                For Firefox
                </a><br/>
                <a href="https://addons.opera.com/en/extensions/details/metamask/">
                For Opera
                </a><br/>
                <a href="https://brave.com/">
                Get Brave browser
                </a>
            </div>
        );
    }
}

export default GetMetamask;
