// //overrides metamask v0.2 for our 1.0 version.  
//1.0 lets us use async and await instead of promises
import Web3 from 'web3';

var web3;
if (window.ethereum) {
    web3 = new Web3(window.ethereum);
    try {
        // Request account access if needed
        window.ethereum.enable();
    } catch (error) {
        console.error(error);
    }
}
// Legacy dapp browsers...
else if (window.web3) {
    // Use Mist/MetaMask's provider.
    web3 = window.web3;
    console.log('Injected web3 detected.');
}
// Fallback to localhost; use dev console port by default...
else {
    web3 = undefined
    console.log('No web3 instance injected, using Local web3.');
}

window.addEventListener('load', async () => {
    // Wait for loading completion to avoid race conditions with web3 injection timing.
     if (window.ethereum) {
       const web3 = new Web3(window.ethereum);
       try {
         // Request account access if needed
         await window.ethereum.enable();
         // Acccounts now exposed
         return web3;
       } catch (error) {
         console.error(error);
       }
     }
     // Legacy dapp browsers...
     else if (window.web3) {
       // Use Mist/MetaMask's provider.
       const web3 = window.web3;
       console.log('Injected web3 detected.');
       return web3;
     }
     // Fallback to localhost; use dev console port by default...
     else {
       const provider = new Web3.providers.HttpProvider('http://127.0.0.1:9545');
       const web3 = new Web3(provider);
       console.log('No web3 instance injected, using Local web3.');
       return web3;
     }
   });

export default web3;
